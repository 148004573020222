import AnalysisButton from "pages/main/tableprops/AnalysisButton";
import { ZineCardProps } from "types/main/zineCardProps";
import st from "./ZineMovieCard.module.scss";

const ZineMovieCard = ({
  data,
  openModal,
}: {
  data: ZineCardProps;
  openModal: (data: ZineCardProps) => void;
}) => {
  return (
    <div className={st.card_container}>
      <img src={data?.widthPoster} alt={data?.movie} />
      <div className={st.information}>
        <span className={st.title}>{data?.movie}</span>
        <p className={st.content}>{data?.mzContent}</p>
        <div className={st.button}>
          <AnalysisButton
            hoverEffect={false}
            width={167}
            height={44}
            borderColor="#111111"
            backColor="#ffffff"
            textColor="#111111"
            handleClick={() => openModal(data)}
          />
        </div>
      </div>
    </div>
  );
};

export default ZineMovieCard;
