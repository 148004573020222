import { ModifiedMovieProps, MovieProps } from './../types/main/movieProps.d';

const modifyTop10Data : (data : MovieProps) => ModifiedMovieProps 
= (data : MovieProps) => {
    let modified : ModifiedMovieProps = {
        idx: data.idx,
        grade: data.grade,
        country: data.country,
        title: data.title,
        imageUrl: data.imageUrl,
        openDate: data.openDate,
        watchedTotalCount: data.watchedTotalCount.toLocaleString('ko-KR'),
        ticketingPercent: data.ticketingPercent,
        movieBuzzCount: data.movieBuzzCount.toLocaleString('ko-KR'),
        exposure: data.exposure,
        responsiveness: data.responsiveness
    }

    return modified;
}

export default modifyTop10Data;