import MainPage from "pages/main/MainPage";

function App() {
  return (
    <div>
      <MainPage />
    </div>
  );
}

export default App;
