import useGetApi from "api/useGetApi";
import st from "components/analysisModal/ExpectedAudience.module.scss";
import { useEffect } from "react";
import ExpectedBox from "./ExpectedBox";

interface ExpectedAudienceProps {
  movieIdx: number | undefined;
}

const ExpectedAudience = ({ movieIdx }: ExpectedAudienceProps) => {
  const { data: expectedDatas, refetch } = useGetApi(
    `expectedAudience`,
    `/movie-top-ten/${movieIdx}/chat-gpt/predictive`,
    { enabled: !!movieIdx }
  );

  useEffect(() => {
    if (movieIdx !== undefined) {
      refetch();
    }
  }, [movieIdx, refetch]);

  return (
    <div className={st.section}>
      {expectedDatas ? (
        <div key={expectedDatas.idx}>
          <p>
            {expectedDatas.movie}
            <span className={st.gray}>AI 예상 관객량</span>
          </p>
          <div className={st.boxes}>
            <ExpectedBox
              title={"최저 흥행 예상"}
              amount={`${
                typeof expectedDatas.lowestWatchedCount === "number"
                  ? expectedDatas.lowestWatchedCount.toLocaleString()
                  : 0
              } 이하`}
            />
            <ExpectedBox
              title={"평균 흥행 예상"}
              amount={
                typeof expectedDatas.middleWatchedCount === "number"
                  ? expectedDatas.middleWatchedCount.toLocaleString()
                  : 0
              }
            />
            <ExpectedBox
              title={"최고 흥행 예상"}
              amount={`${
                typeof expectedDatas.highestWatchedCount === "number"
                  ? expectedDatas.highestWatchedCount.toLocaleString()
                  : 0
              } 이상`}
            />
          </div>
        </div>
      ) : (
        <p>예상 관객 데이터가 없습니다.</p>
      )}
    </div>
  );
};

export default ExpectedAudience;
