import Logo from "components/common/Logo";

import st from "./Footer.module.scss"

const Footer = () => {
    return (
        <div className={st.footer_container}>
            <div className={st.content_container}>
                <Logo mode={"dark"}/>
                <div>
                    <span>Movie Data Specialist Platform</span>
                    <span>영화 데이터 전문 플랫폼</span>
                </div>
                <div>
                    <span>서울특별시 강남구 도산대로59길 26, 2층(청담동, 히든힐하우스)</span>
                    <span>help@sociallouder.co.kr</span>
                </div>
                <div>
                    <span>사업자등록번호 : 658-88-02426 <span className={st.underline}>[사업자확인]</span></span>
                    <span>상호 : 주식회사 소셜라우더 | 대표자 : 최동인, 손나래</span>
                    <span>개인정보보호책임자 : 김하늘</span>
                </div>
            </div>
        </div>
    )
}

export default Footer;