import { MovieProps } from "types/main/movieProps";
import { MovieAICardProps, MovieAIProps } from "../types/main/movieAIProps";

const makeAICardData: (
  ai: MovieAIProps,
  movie: MovieProps
) => MovieAICardProps = (ai: MovieAIProps, movie: MovieProps) => {
  let modified: MovieAICardProps = {
    idx: 0,
    movie: "-",
    lowestWatchedCount: "-",
    middleWatchedCount: "-",
    highestWatchedCount: "-",
    predictiveAnswer: "-",
    openDate: "-",
    country: "-",
    title: "-",
    imageUrl: "-",
  };

  if (ai) {
    modified.idx = ai.idx !== undefined ? ai.idx : 0;
    modified.movie = ai.movie || "-";

    modified.lowestWatchedCount = (ai.lowestWatchedCount ?? 0).toLocaleString(
      "ko-KR"
    );
    modified.middleWatchedCount = (ai.middleWatchedCount ?? 0).toLocaleString(
      "ko-KR"
    );
    modified.highestWatchedCount = (ai.highestWatchedCount ?? 0).toLocaleString(
      "ko-KR"
    );
    modified.predictiveAnswer = ai.predictiveAnswer || "-";
  }

  if (movie) {
    modified.country = movie.country || "-";
    modified.title = movie.title || "-";
    modified.imageUrl = movie.imageUrl || "-";
    modified.openDate = movie.openDate
      ? movie.openDate.replace(/-/g, ".")
      : "-";
  }

  return modified;
};

export default makeAICardData;
