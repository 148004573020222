import { useQuery } from "@tanstack/react-query";
import { API_URL } from "api/URL";

const useGetApi = (key: string, url: string, queryOptions?: Object) => {
  const COMPOUND_URL = `${API_URL}${url}`;

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const getDatas = async () => {
    try {
      const res = await fetch(COMPOUND_URL, options);
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(
          `Error from server: ${errorResponse.message || res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      return { error };
    }
  };

  const { refetch, data, isLoading, isError, error } = useQuery({
    queryKey: [key],
    queryFn: getDatas,
    ...queryOptions,
  });

  return { data, isLoading, isError, error, refetch };
};

export default useGetApi;
