import { create } from "zustand";

import { MovieProps } from "types/main/movieProps";

interface MoviePropsStore {
    movieDatas: MovieProps[];
    setMovieDatas: (movies : MovieProps[]) => void;
  }

const useMovieTop10Store = create<MoviePropsStore>((set) => ({
    movieDatas: [],
    setMovieDatas: (movies : MovieProps[]) => set({movieDatas : movies})
}));

export default useMovieTop10Store;