import { useMutation } from "@tanstack/react-query";
import { API_URL } from "api/URL";

const usePostApi = (
  key: string,
  url: string,
  body: any,
  setEmail: (value: string) => void
) => {
  const COMPOUND_URL = `${API_URL}${url}`;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(body),
  };

  const postDatanotjson = async () => {
    const res = await fetch(COMPOUND_URL, options);
    const contentType = res.headers.get("Content-Type");
    const isJson = contentType && contentType.includes("application/json");

    const data = isJson ? await res.json() : await res.text();

    if (!res.ok) {
      throw new Error(isJson ? data.message : data);
    }

    return data;
  };

  const { mutate, data, error, isError, isPending } = useMutation({
    mutationKey: [key],
    mutationFn: postDatanotjson,
    onSuccess: (responseData) => {
      if (responseData === "이미 등록된 이메일 입니다.") {
        alert(responseData);
      } else {
        alert("이메일이 성공적으로 등록되었습니다.");
      }
      setEmail(""); // 성공 시 이메일 입력값 초기화
    },
    onError: (error) => {
      alert(`요청 실패: ${error}`);
      setEmail(""); // 실패 시 이메일 입력값 초기화
    },
  });

  return { data, isPending, error, isError, mutate };
};

export default usePostApi;
