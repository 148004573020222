import { EventCardProps } from 'types/main/eventCardProps';

import st from "./EventCard.module.scss";

const EventCard = ({ data } : { data : EventCardProps }) => {

  return (
    <div className={st.card_container} onClick={() => {
        window.open(data?.url, "_blank");
    }}>
        <div className={st.image}>
            <img src={data?.image} alt="event" />
        </div>
        <div className={st.title}>
            <p>{data?.title}</p>
            <p>{data?.description}</p>
            <div className={st.per}>
                <div className={st.bar}>
                    <div
                        className={st.progress}
                        style={{width: data?.percentage}}
                    ></div>
                </div>
                <span className={st.percentage}>{data?.percentage}</span>
            </div>
        </div>
        <div className={st.result}>
            <div>
                <span>지원자</span>
                <p>{data?.applicants}</p>
            </div>
            <div>
                <span>모집인원</span>
                <p>{data?.recruitment}</p>
            </div>
            <div>
                <span>신청마감일</span>
                <p>{data?.deadline}</p>
            </div>
        </div>
    </div>
  )
}

export default EventCard