import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from 'swiper';
import { useEffect, useState } from "react";

import {ReactComponent as LeftButton} from "../../../assets/svg/leftButton.svg";
import {ReactComponent as RightButton} from "../../../assets/svg/rightButton.svg";
import EventCard from "components/main/EventCard";
import data from "../../../components/main/datas/eventData.json";

import 'swiper/scss';
import st from "./EventSection.module.scss";
import { Autoplay } from "swiper/modules";

const EventSection = () => {
    const [ slidesPerView, setSlidesPerView ] = useState<number>(3);
    const [ focused, setFocused ] = useState<SwiperCore>();

    const handleResize = () => {
        const width = window.innerWidth;
        
        switch (true) {
          case width >= 1700 :
            setSlidesPerView(3);
            break;
    
          case width >= 1200 :
            setSlidesPerView(2);
            break;
          
          default :
            setSlidesPerView(1);
            break;
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    
    return (
        <div className={st.section_container}>
            <div className={st.content_container}>
                <div className={st.page_navigation} data-aos="fade-right">
                    <span className={st.subtitle}>movie board event</span>
                    <div className={st.navigation_main}>
                        <span className={st.title}>이벤트 참여</span>
                        <div className={st.page_buttons}>
                            <LeftButton className={st.icons} onClick={() => {focused?.slidePrev();}}/>
                            <RightButton className={st.icons} onClick={() => {focused?.slideNext();}}/>
                        </div>
                    </div>
                </div>
                <div className={st.swiper_container} data-aos="fade-left">
                    <Swiper
                        modules={[Autoplay]}
                        spaceBetween={30}
                        slidesPerView={slidesPerView}
                        onSwiper={setFocused}
                        autoplay
                    >
                        {data.eventdata.map ((item, key) => {
                            return (
                                <SwiperSlide key={key} >
                                    <EventCard data={item}/>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default EventSection;