import useGetApi from "api/useGetApi";
import { ReactComponent as Instagram } from "assets/svg/instagram.svg";
import { default as InstagramLogo } from "assets/svg/instagramLogo.svg";
import { ReactComponent as Twitter } from "assets/svg/twitter.svg";
import TwitterLogo from "assets/svg/twitterLogo.svg";
import { ReactComponent as Youtube } from "assets/svg/youtube.svg";
import YoutubeLogo from "assets/svg/youtubeLogo.svg";
import ColumnChart from "components/analysisModal/ColumnChart";
import ViralDataStatistics from "components/onlineDataModal/ViralDataStatistics";
import { useEffect, useState } from "react";
import st from "./ViralData.module.scss";

interface ViralDataProps {
  movieIdx: string;
}

const ViralData = ({ movieIdx }: ViralDataProps) => {
  const { data: apiData, refetch } = useGetApi(
    "viralCounts",
    `/movie-top-ten/movie-zine/${movieIdx}`,
    {
      enabled: !!movieIdx,
    }
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    if (movieIdx) {
      refetch();
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [movieIdx, refetch]);

  // API에서 받은 데이터 확인
  const viralCounts = apiData?.movieGraphData?.viralCounts || {
    instagram: 0,
    twitter: 0,
    youtube: 0,
  };

  let instagramCount = Number(viralCounts.instagram);
  let twitterCount = Number(viralCounts.twitter);
  let youtubeCount = Number(viralCounts.youtube);

  if (isNaN(instagramCount)) {
    instagramCount = 0;
  }
  if (isNaN(twitterCount)) {
    twitterCount = 0;
  }
  if (isNaN(youtubeCount)) {
    youtubeCount = 0;
  }

  const totalViralCounts = instagramCount + twitterCount + youtubeCount;

  const instagramPercentage =
    totalViralCounts > 0
      ? Math.round((instagramCount / totalViralCounts) * 100)
      : 0;
  const twitterPercentage =
    totalViralCounts > 0
      ? Math.round((twitterCount / totalViralCounts) * 100)
      : 0;
  const youtubePercentage =
    totalViralCounts > 0
      ? Math.round((youtubeCount / totalViralCounts) * 100)
      : 0;

  const chartData = [
    {
      name: "인스타그램",
      value: instagramCount,
      bulletSettings: {
        src: isMobile ? null : InstagramLogo,
      },
    },
    {
      name: "트위터",
      value: twitterCount,
      bulletSettings: {
        src: isMobile ? null : TwitterLogo,
      },
    },
    {
      name: "유튜브",
      value: youtubeCount,
      bulletSettings: {
        src: isMobile ? null : YoutubeLogo,
      },
    },
  ];

  const statisticDatas = [
    {
      sns: <Instagram />,
      title: "인스타그램",
      amount: instagramCount.toLocaleString(),
      percentage: instagramPercentage,
      bgColor: "#E4405F",
    },
    {
      sns: <Twitter />,
      title: "트위터",
      amount: twitterCount.toLocaleString(),
      percentage: twitterPercentage,
      bgColor: "#55ACEE",
    },
    {
      sns: <Youtube />,
      title: "유튜브",
      amount: youtubeCount.toLocaleString(),
      percentage: youtubePercentage,
      bgColor: "#FF0000",
    },
  ];

  return (
    <div className={st.section}>
      <h3>바이럴데이터 통계</h3>
      <div className={st.graph}>
        <ColumnChart chartId2="viralChart" data={chartData} />
      </div>
      <div className={st.boxes}>
        {statisticDatas.map((data, idx) => (
          <ViralDataStatistics
            key={idx}
            sns={data.sns}
            title={data.title}
            amount={data.amount}
            percentage={`${data.percentage}%`}
            totalAmount={totalViralCounts}
            bgColor={data.bgColor}
          />
        ))}
      </div>
    </div>
  );
};

export default ViralData;
