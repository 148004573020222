import { MovieAICardProps } from "types/main/movieAIProps";
import { create } from "zustand";

interface ModalStore {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  selectedCardData: MovieAICardProps | null;
  setSelectedCardData: (data: MovieAICardProps | null) => void;
}

const useModalStore = create<ModalStore>((set) => ({
  isModalOpen: false,
  setIsModalOpen: (isOpen) => set({ isModalOpen: isOpen }),
  selectedCardData: null,
  setSelectedCardData: (data) => set({ selectedCardData: data }),
}));

export default useModalStore;
