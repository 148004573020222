import useGetApi from "api/useGetApi";
import st from "components/onlineDataModal/AIMarketing.module.scss";
import { useEffect, useMemo } from "react";
import AIRecommend from "./AIRecommand";

interface AIMarketingProps {
  movieIdx: number | undefined;
}

const AIMarketing = ({ movieIdx }: AIMarketingProps) => {
  const { data: apiData, refetch: refetchApiData } = useGetApi(
    `aiMarketingAnalysis`,
    `/movie-top-ten/${movieIdx}/chat-gpt/analysis`,
    { enabled: !!movieIdx }
  );

  const { data: apiDataPercent, refetch: refetchApiDataPercent } = useGetApi(
    `aiMarketingAnalysisPercent`,
    `/movie-top-ten/${movieIdx}/chat-gpt/analysis-percent`,
    { enabled: !!movieIdx }
  );

  useEffect(() => {
    if (movieIdx) {
      refetchApiData();
      refetchApiDataPercent();
    }
  }, [movieIdx, refetchApiData, refetchApiDataPercent]);

  const calculateChartData = (positive: number, negative: number) => {
    const total = positive + negative;
    if (total === 0) return { data: [], total: 0 };
    return {
      data: [
        { value: (positive / total) * 100, label: "긍정적" },
        { value: (negative / total) * 100, label: "부정적" },
      ],
      total,
    };
  };

  const chartData1 = useMemo(() => {
    if (!apiDataPercent || !apiDataPercent.marketingCounts)
      return { data: [], total: 0 };
    const { positive = 0, negative = 0 } = apiDataPercent.marketingCounts || {};
    return calculateChartData(positive, negative);
  }, [apiDataPercent]);

  const chartData2 = useMemo(() => {
    if (!apiDataPercent || !apiDataPercent.riskCounts)
      return { data: [], total: 0 };
    const { positive = 0, negative = 0 } = apiDataPercent.riskCounts || {};
    return calculateChartData(positive, negative);
  }, [apiDataPercent]);

  const chartData3 = useMemo(() => {
    if (!apiDataPercent || !apiDataPercent.competitionCounts)
      return { data: [], total: 0 };
    const { movie = 0, competitor = 0 } =
      apiDataPercent.competitionCounts || {};
    const total = movie + competitor;
    const data = [
      { value: (movie / total) * 100, label: "긍정적" },
      { value: (competitor / total) * 100, label: "부정적" },
    ];
    return { data, total };
  }, [apiDataPercent]);

  return (
    <div className={st.section}>
      <h3 className={st.title}>AI 마케팅 분석 및 업무 추천</h3>
      <AIRecommend
        title={"마케팅분석"}
        content={apiData?.marketingAnswer || "N/A"}
        chartData={chartData1.data}
        chartId="chart1"
        total={chartData1.total}
      />
      <AIRecommend
        title={"리스크분석"}
        content={apiData?.riskAnswer || "N/A"}
        chartData={chartData2.data}
        chartId="chart2"
        total={chartData2.total}
      />
      <AIRecommend
        title={"경쟁영화대비"}
        content={apiData?.competitionAnswer || "N/A"}
        chartData={chartData3.data}
        chartId="chart3"
        total={chartData3.total}
      />
    </div>
  );
};

export default AIMarketing;
