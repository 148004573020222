import usePostApi from "api/usePostApi";
import { useState } from "react";
import st from "./NewsLetterSection.module.scss";

const NewsLetterSection = () => {
  const [email, setEmail] = useState("");
  const { mutate } = usePostApi(
    "newsletterSignup",
    "/my-mailer/mail-user",
    { userEmail: email },
    setEmail
  );

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubscribe = () => {
    if (!isValidEmail(email)) {
      alert("유효한 이메일 주소를 입력해주세요.");
      setEmail("");
      return;
    }

    mutate();
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubscribe();
    }
  };

  return (
    <div className={st.section_container}>
      <div className={st.content_container}>
        <div className={st.title_container}>
          <span className={st.title}>영화 정보 뉴스레터 신청</span>
          <span className={st.description}>
            영화 정보 뉴스레터는 구독자들에게
            <br className={st.line_break} /> 최신 영화 소식 및 리뷰를
            전달해드립니다.
          </span>
        </div>
        <div className={st.input_container}>
          <input
            type="text"
            placeholder="Your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress} // Enter 키 처리
          />
          <button onClick={handleSubscribe}>신청하기</button>
        </div>
      </div>
    </div>
  );
};

export default NewsLetterSection;
