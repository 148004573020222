import img4 from "../../../assets/svg/aiWithHands.svg";
import img2 from "../../../assets/svg/monitor.svg";
import img1 from "../../../assets/svg/movieSlate.svg";
import img3 from "../../../assets/svg/target.svg";
import st from "./DataAnalyzeSection.module.scss";

const DataAnalyzeSection = () => {
  const buttonList = [
    {
      imgpath: img1,
      label: "우리영화 분석",
      description:
        "영화의 관객 반응, 평점, 리뷰 및 흥행 성과 등을 종합적으로 분석합니다.",
    },
    {
      imgpath: img2,
      label: "전체버즈량 분석",
      description:
        "영화와 관련된 언급 및 소셜 미디어 활동 등 다양한 데이터를 수집하여 해당 영화에 대한 대중의 관심도와 인지도를 평가합니다.",
    },
    {
      imgpath: img3,
      label: "경쟁영화 분석",
      description:
        "동일 시기 개봉한 영화들의 흥행 성과 및 마케팅 활동 등을 비교하여 해당 영화가 시장에서 차지하는 위치와 흥행 가능성을 평가합니다.",
    },
    {
      imgpath: img4,
      label: "AI예측분석",
      description:
        "인공지능 알고리즘을 활용하여 영화의 개봉 전후 흥행 성과를 예측하고,이를 바탕으로 필요한 인사이트를 제공합니다.",
    },
  ];

  return (
    <div className={st.section_container}>
      <div className={st.content_container} data-aos="fade-right">
        <span className={st.title}>
          <span>ONLINE</span>
          <span>DATA</span>
          <span>ANALYZE</span>
        </span>
        <div className={st.button_container}>
          {buttonList.map((item, key) => (
            <div className={st.card} key={key}>
              <div className={st.card_inner}>
                <div className={st.card_front}>
                  <img src={item.imgpath} alt="online_image" />
                  <span>{item?.label}</span>
                </div>
                <div className={st.card_back}>
                  <img src={item.imgpath} alt="online_image_back" />
                  <span>{item?.label}</span>
                  <p>{item?.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataAnalyzeSection;
