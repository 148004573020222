import PageNextIcon from '@rsuite/icons/PageNext';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import { PlanData } from 'types/main/planData';
import { OptionLabelProps } from 'types/main/optionLabelProps';

import st from "./PlanCard.module.scss";

function PlanCard({ data, color = "gray" } : { data : PlanData, color: string } ) {

    const optionLabel : OptionLabelProps[] = [
        {
            key : "Mailing",
            label : "메일링"
        }, 
        {
            key : "MovieZine",
            label : "무비진"
        },
        {
            key : "MovieTop10",
            label : "영화 Top 10 데이터"
        },
        {
            key : "AnalyzeBookmarkData",
            label : "즐겨찾기 10편 데이터 분석"
        }
    ];

    const permission = Object.values(data?.options);

    return (
        <div className={st.card_container}>
            <div className={st[color]}>
                <span>{data?.recommandation}</span>
            </div>
            <div className={st.contents}>
                <div className={st.plandetails}>
                    <div>
                        <span>{data?.price}</span>
                        <span>{data?.description}</span>
                    </div>
                    {data?.payEveryMonth && <span>매월결제</span>}
                </div>
                <div className={st.line}/>
                <div className={st.optionlist}>
                    {permission.map((item, key) => {
                        return (
                                <div key={key}>
                                    <span>{optionLabel[key]?.label}</span>
                                    {item ? <CheckRoundIcon/> : <WarningRoundIcon className={st.warning}/>}
                                </div>
                            )
                        })
                    }
                </div>
                <div className={st.button} onClick={() => {alert("지금은 무료 기간입니다.")}}>
                    <PageNextIcon/>
                </div>
            </div>
        </div>
    )
}

export default PlanCard;