import { ReactComponent as Cancel } from "assets/svg/cancel.svg";
import SubscriptionOnly from "components/analysisModal/SubscriptionOnly";
import st from "pages/modal/AnalysisModal.module.scss";
import { forwardRef, useEffect, useState } from "react";
import useModalStore from "stores/modal/useModalStore";
import { MovieAICardProps } from "types/main/movieAIProps";
import TopTenAnalysis from "../../components/analysisModal/TopTenAnalysis";

interface AnalysisModalProps {
  data: MovieAICardProps | null;
  onClose: () => void;
}

const AnalysisModal = forwardRef<HTMLDialogElement, AnalysisModalProps>(
  ({ data, onClose }, ref) => {
    const { selectedCardData } = useModalStore();
    const [selectedMovie, setSelectedMovie] = useState<MovieAICardProps | null>(
      selectedCardData
    );

    useEffect(() => {
      if (ref && typeof ref !== "function" && ref.current) {
        document.body.style.overflow = "hidden";

        return () => {
          document.body.style.overflow = "auto";
        };
      }
      return;
    }, [ref]);

    const handleMovieChange = (movie: MovieAICardProps) => {
      setSelectedMovie(movie);
    };

    return (
      <div className={st.modal_background}>
        <dialog className={st.dialog} ref={ref}>
          <div className={st.content}>
            <div className={st.between}>
              <p>
                MOVIEBOARD
                <span className={st.next}>by Sociallouder</span>
              </p>
              <Cancel className={st.cancle_btn} onClick={onClose} />
            </div>
            {selectedMovie && selectedMovie.idx !== undefined && (
              <>
                <TopTenAnalysis
                  initialSelectedMovie={selectedMovie}
                  onMovieChange={handleMovieChange}
                />
                <SubscriptionOnly movieIdx={selectedMovie.idx} />
              </>
            )}
          </div>
        </dialog>
      </div>
    );
  }
);

export default AnalysisModal;
