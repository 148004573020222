import st from "./MovieTitle.module.scss";

const MovieTitle = ({ text, id } : { text : string, id : number }) => {

    return (
        <div className={st.title}>
            <div>{id}</div>
            <span>{text}</span>
        </div>
    )
}

export default MovieTitle;