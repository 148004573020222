import useGetApi from "api/useGetApi";
import { useEffect } from "react";
import st from "./ExpectAudience.module.scss";
import ExpectedBox from "./ExpectBox";

interface ExpectedAudienceProps {
  movieAudienceIdx: number | undefined;
}

const ExpectAudience = ({ movieAudienceIdx }: ExpectedAudienceProps) => {
  const { data: expectedDatas, refetch } = useGetApi(
    `expectedAudience`,
    `/movie-top-ten/${movieAudienceIdx}/chat-gpt/predictive`,
    { enabled: !!movieAudienceIdx }
  );

  useEffect(() => {
    if (movieAudienceIdx !== undefined) {
      refetch();
    }
  }, [movieAudienceIdx, refetch]);

  return (
    <div className={st.section}>
      {expectedDatas ? (
        <div key={expectedDatas.idx}>
          <p>
            <span className={st.gray}>AI 예상 관객량</span>
          </p>
          <div className={st.boxes}>
            <ExpectedBox
              title={"최저 흥행 예상"}
              amount={
                expectedDatas.lowestWatchedCount !== undefined
                  ? expectedDatas.lowestWatchedCount
                      .toLocaleString()
                      .replace(/\s/g, "") + " 이하"
                  : "0 이하"
              }
            />
            <ExpectedBox
              title={"평균 흥행 예상"}
              amount={
                expectedDatas.middleWatchedCount !== undefined
                  ? expectedDatas.middleWatchedCount
                      .toLocaleString()
                      .replace(/\s/g, "")
                  : "0"
              }
            />
            <ExpectedBox
              title={"최고 흥행 예상"}
              amount={
                expectedDatas.highestWatchedCount !== undefined
                  ? expectedDatas.highestWatchedCount
                      .toLocaleString()
                      .replace(/\s/g, "") + " 이상"
                  : "0 이상"
              }
            />
          </div>
        </div>
      ) : (
        <p>예상 관객 데이터가 없습니다.</p>
      )}
    </div>
  );
};

export default ExpectAudience;
