import Logo from "components/common/Logo";
import NoticeBar from "layout/noticebar/NoticeBar";
import { ReactComponent as UserLight } from "../../assets/svg/user.svg";
import { ReactComponent as UserDark } from "../../assets/svg/userDark.svg";

import st from "./NavBar.module.scss";

export interface NavBarElement {
  path?: string;
  label: string;
  ref: HTMLDivElement | undefined;
}

interface NavBarProps {
  hasNotice?: boolean;
  mode?: "light" | "dark";
  list: NavBarElement[] | undefined;
}

const NavBar = ({ hasNotice = false, mode = "dark", list }: NavBarProps) => {
  const handleClick = (item: HTMLDivElement) => {
    item?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <nav
      className={
        mode === "dark" ? st.nav_container_dark : st.nav_container_light
      }
    >
      {hasNotice && (
        <div className={st.notice}>
          <NoticeBar />
        </div>
      )}
      <div className={st.nav}>
        <Logo mode={"light"} />
        <div
          className={mode === "light" ? st.nav_menu_light : st.nav_menu_dark}
        >
          {list?.map((item, key: number) => {
            return (
              <div
                key={key}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                onClick={() => handleClick(item?.ref!)}
              >
                {item.label}
              </div>
            );
          })}
        </div>

        {mode === "light" ? (
          <UserLight
            className={st.user}
            onClick={() => {
              window.open("https://membership.web-movieboard.com/", "_blank");
            }}
          />
        ) : (
          <UserDark
            className={st.user}
            onClick={() => {
              window.open("https://membership.web-movieboard.com/", "_blank");
            }}
          />
        )}
      </div>
    </nav>
  );
};

export default NavBar;
