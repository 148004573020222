import st from "./NoticeBar.module.scss"

const NoticeBar = () => {
    return (
        <div className={st.notice_container}>
            <span className={st.title}>
                NOTICE
            </span>
            <span className={st.content}>
                Beta 버전 기간에는 무료로 사용가능 합니다.
            </span>
        </div>
    )
}

export default NoticeBar;