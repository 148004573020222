import { OnlineDatas } from "types/modal/onlineStatistics";
import st from "./ViralDataStatistics.module.scss";

interface ViralDataStatisticsProps extends OnlineDatas {
  totalAmount: number;
}

const ViralDataStatistics = ({
  sns,
  title,
  amount,
  bgColor,
  totalAmount,
}: ViralDataStatisticsProps) => {
  const numericAmount =
    typeof amount === "string"
      ? parseInt(amount.replace(/,/g, ""), 10)
      : Number(amount);
  const validAmount = isNaN(numericAmount) ? 0 : numericAmount;

  const percentage = totalAmount
    ? Math.round((validAmount / totalAmount) * 100)
    : 0;

  return (
    <div className={st.box}>
      <div>
        <span className={st.circle}>{sns}</span>
        <span className={st.data}>
          {title}
          <span>{validAmount.toLocaleString()}</span>{" "}
        </span>
      </div>
      <div className={st.between}>
        <p>전체</p>
        <p>{percentage}%</p>
      </div>
      <div className={st.progressBar}>
        <div
          className={st.progress}
          style={{ width: `${percentage}%`, backgroundColor: bgColor }}
        ></div>
      </div>
    </div>
  );
};

export default ViralDataStatistics;
