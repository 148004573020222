import { ReactComponent as Cancel } from "assets/svg/cancel.svg";
import { ReactComponent as Crown } from "assets/svg/crown.svg";
import { forwardRef, useEffect } from "react";
import AIMarketing from "./AIMarketing";
import ExpectAudience from "./ExpectAudience";
import st from "./MovieZine.module.scss";
import OnlineData from "./OnlineData";
import ViralData from "./ViralData";

interface AnalysisModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  verticalPoster: string;
}

const MoviezineModal = forwardRef<HTMLDialogElement, AnalysisModalProps>(
  ({ open, onClose, title, verticalPoster }, ref) => {
    useEffect(() => {
      const disableScroll = () => {
        document.body.style.overflow = "hidden";
      };

      const enableScroll = () => {
        document.body.style.overflow = "auto";
      };

      if (open) {
        disableScroll();
      } else {
        enableScroll();
      }

      return () => enableScroll();
    }, [open]);

    const posterUrl = `https://image.web-movieboard.com/movie_poster/${verticalPoster}`;

    return (
      <>
        <div
          className={st.modal_background}
          style={{ display: open ? "block" : "none" }}
        />
        <dialog className={st.dialog} ref={ref}>
          <div className={st.content}>
            <div className={st.between}>
              <p>
                MOVIEBOARD
                <span className={st.next}>by Sociallouder</span>
              </p>
              <Cancel className={st.cancle_btn} onClick={onClose} />
            </div>
            <div className={st.section}>
              <div className={st.info_box}>
                <div className={st.image_container}>
                  <img src={posterUrl} alt="영화 포스터" className={st.image} />
                </div>
                <div className={st.title_container}>
                  <h1>{title}</h1>
                  <ExpectAudience movieAudienceIdx={title} />
                </div>
              </div>
            </div>
            <div className={st.subscriber_title}>
              <Crown className={st.crown_icon} />
              <p>구독전용</p>
            </div>
            <OnlineData movieBuzzIdx={title} />
            <ViralData movieIdx={title} />
            <AIMarketing movieIdx={title} />
          </div>
        </dialog>
      </>
    );
  }
);

export default MoviezineModal;
