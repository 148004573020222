import { RefObject } from "react";

import data from "../../../components/main/datas/planList.json";
import PlanCard from "components/main/PlanCard";

import st from "./PlanSection.module.scss";

const PlanSection = ({ targetRef } : { targetRef : RefObject<HTMLDivElement[]> }) => {
    
    const setColor = (plan : string) => {
        switch (plan) {
            case "무료 이용" :
                return "gray";
            
            case "₩9,000" :
                return "orange_gradient";

            case "₩99,000" :
                return "red_gradient";
            
            default :
                return "gray";
        }
    }

    return (
        <div className={st.section_container} ref={(el : HTMLDivElement) => (targetRef?.current?.fill(el, 3, 4))}>
            <div className={st.content_container} data-aos="fade-up">
                <span className={st.guide}>지금 무료 이용으로<br className={st.line_break}/> 시작해보세요.</span>
                <div className={st.plancards}>
                    {data.planList.map((item, key) => {
                        return <PlanCard key={key} data={item} color={setColor(item?.price)}/>
                    })}
                </div>
            </div>
        </div>
    )
}

export default PlanSection;